/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
// // const imagePath = (name) => images(name, true)

// // console.log('Hello World from Webpacker')
// // Support component names relative to this directory:
// require("@rails/ujs").start();
// // require('datatables.net-buttons-bs4');
// require('components');


// import('../stylesheets/application.scss');



var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);


// app/javascript/packs/application.js
document.addEventListener('DOMContentLoaded', function() {
  const deploymentTab = document.getElementById('deployment_tab');
  const cloneJobTab = document.getElementById('clone_job_tab');

  deploymentTab.addEventListener('click', function() {
    loadForm('new_deployment_content', '/release_mains/load_deployment_form');
  });

  cloneJobTab.addEventListener('click', function() {
    loadForm('new_clone_job_content', '/release_mains/load_clone_form');
  });

  function loadForm(contentId, url) {
    // Clear all form contents
    document.querySelectorAll('.form_content').forEach(content => {
      content.innerHTML = '';
    });

    const targetContent = document.getElementById(contentId);

    fetch(url, {
      headers: {
        'X-CSRF-Token': getCsrfToken()
      }
    })
    .then(response => response.text())
    .then(html => {
      targetContent.innerHTML = html;
      initializeDateTimePicker();
    })
    .catch(error => console.error('Error loading form:', error));
  }
  $('#datetimepicker').datetimepicker({
    inline: true,
    step: 1,
  });
  // Initially load the deployment form
  loadForm('new_deployment_content', '/release_mains/load_deployment_form');
});

function getCsrfToken() {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

function initializeDateTimePicker() {
  $('#datetimepicker').datetimepicker({
    inline: true,
    step: 1,
  });
}